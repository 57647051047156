import React, { useState, useEffect, useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  ContentHeader,
  Dropdown,
  FlexRow,
  Form,
  FormGroup,
  Icon,
  Input,
  LoadingIndicator,
  Modal,
  Panel,
  Popover,
  TextArea,
  useForm
} from "@ufginsurance/ui-kit";
import { getIsNewAccountOrObligee } from "../shared/util";
import { debounceApi } from "../../../util/debounce";
import { toTitleCase } from "../../../components/Factory";
import {
  suretyLegalEntities,
  SuretyConstructionSpecialty,
  ConstructionSpecialtyTypeOtherNotListed,
  legalEntityTitleDisplay,
  accountStatus,
  AccountNameMaxLength
} from "../constants/suretyDictionary";
import * as accountSearch from "./accountSearch";
import * as routes from "../../../constants/routes";
import "./CustomerInfo.scss";
import { Translations } from "../../../components/translations";
import PropTypes from "prop-types";
import VerifyAddress from "../shared/AddressVerification/VerifyAddress";
import { eligibilityInitValues } from "../eligibility/Eligibility";
import { getEligibility } from "../quickQuotingServices";
import MultiAddress from "../shared/MultiAddress/MultiAddress";
import BottomNav from "../shared/BottomNav";

const CustomerInfo = ({
  activeAgencyCode,
  history,
  agencyAccountsListData,
  fetchAgencyAccounts,
  ownersListData,
  fetchOwners,
  clearSuretyQuote,
  updateSuretyQuoteData,
  suretyQuote,
  cacheId,
  fetchSuretyQuoteAgencyData,
  licensedStates,
  suretyZip,
  fetchSuretyZip,
  isOwnershipPercentageValid,
  hasWholeNumberOwnershipPercents,
  isOwnerSsnValid,
  clearOwners
}) => {
  const { suretyWhitelistNysEnabled } = useFlags();
  // default form values
  const initialValues = {
    account_number: "",
    account_name: "",
    address_line: "",
    building_number: "",
    street_name: "",
    zip: "",
    city: "",
    state: "",
    pobox: "",
    business_type: "",
    construction_specialty: "",
    construction_specialty_dd: "",
    legal_business_name: "",
    warning_msg: "",
    legal_name_verified: false,
    status: "",
    construction_other: "",
    ownerData: [],
    eligibility: null,
    bond_information: null,
    fein: "",
    company_number: ""
  };
  const queryParams = useLocation();

  const form = useForm({
    values: suretyQuote ? suretyQuote : initialValues
  });

  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm
  } = form;

  //Continue button status check
  const [readyToContinue, setReadyToContinue] = useState(true);
  const [addressToVerify, setAddressToVerify] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  //Form metadata values
  const [accountNumberInputValue, setAccountNumberInputValue] = useState();
  const [businessNameOptions, setBusinessNameOptions] = useState([]);

  const updateAddressFormFields = useCallback(
    ({ address }) => {
      updateForm({
        values: {
          ...address
        }
      });
    },
    [updateForm]
  );

  const bondContinue = async () => {
    const { data } = await getEligibility(
      activeAgencyCode,
      values?.account_number
    );

    let eligibilityData = data;

    if (Object.entries(data).length === 0) {
      eligibilityData = eligibilityInitValues;
    }

    updateSuretyQuoteData(cacheId, {
      ...processNewQuoteData(values),
      eligibility: {
        ...eligibilityData,
        eligibility_questions: true
      }
    }).then(() => history.push(routes.setStepUrl(4)));
  };

  const ownerContinue = () => {
    if (parseInt(values.account_number) === 0) {
      if (
        !!values.address_line &&
        !!values.city &&
        !!values.state &&
        !!values.zip
      ) {
        setAddressToVerify({
          ...values,
          title: ""
        });

        setShowVerifyModal(true);
      }
    } else {
      goToStep2();
    }
  };

  const goToStep2 = () => {
    updateSuretyQuoteData(cacheId, processNewQuoteData(values)).then(() =>
      history.push(routes.setStepUrl(2))
    );
  };

  const verifiedAddressContinue = verifiedAddress => {
    updateSuretyQuoteData(
      cacheId,
      processNewQuoteData({
        ...values,
        ...verifiedAddress //override with verified address
      })
    ).then(() => history.push(routes.setStepUrl(2)));
  };
  /*
  =================
  FORM INPUT EVENTS
  =================
  */

  // debounce prevents extra calls made - only start searching once user has stopped .5 seconds
  const handleBusinessNameSearch = (value, { action }) => {
    if (action === "input-change") {
      value =
        value.length <= AccountNameMaxLength
          ? value
          : value.substr(0, AccountNameMaxLength);
      setAccountNumberInputValue(value);
      debounceApi(
        accountSearch.search({
          value,
          form,
          businessNameOptions,
          setBusinessNameOptions,
          agencyAccountsListData,
          errorMessageRenewal,
          setErrorMsg
        }),
        500
      );
    }
  };

  const handleBusinessNameChange = ({ value }) => {
    if (!value) {
      setErrorMsg("");
    }
    clearSuretyQuote(cacheId);
    clearOwners();

    setAccountNumberInputValue("");
    accountSearch.select({
      value: (value || "").trim(),
      form,
      businessNameOptions,
      setBusinessNameOptions,
      errorMessageRenewal,
      agencyAccountsListData,
      setErrorMsg,
      setAlertType,
      setShowBondButton,
      setReadyToContinue,
      setDisableFields,
      initialValues
    });
  };

  const handleOnBusinessValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const regex = /[~=`{}_[\\^<>\]]/g;
    const businessSpecialCharNotAllowed = regex.test(value);
    if (!!value && !!businessSpecialCharNotAllowed) {
      fieldErrors.push(
        `Business Name can be alpha or numeric characters only.`
      );
    }
    return fieldErrors;
  };

  const [errorMessageRenewal, setErrorMsg] = useState();

  const [showBondButton, setShowBondButton] = useState(false);
  const [alertType, setAlertType] = useState();
  const [disableFields, setDisableFields] = useState(false);

  // allows the form to be set up before updating the quote storage
  const [formInitialized, setFormInitialized] = useState(false);
  const [initialAgenciesMapped, setInitialAgenciesMapped] = useState(false);

  //buttons state
  useEffect(() => {
    const creditOrdered =
      values.ownerData && !!values.ownerData.find(o => o.order_credit);

    //checking Legal entity appropriate title
    const legalEntityMappingTitle = legalEntityTitleDisplay.find(
      a => a.value === values.business_type
    );

    if (!!legalEntityMappingTitle) {
      const isLegalEntityHasAppropriateTitle = ownersListData?.data?.some(
        a => a.title === legalEntityMappingTitle.label
      );

      if (
        !!parseInt(values.account_number) &&
        values.status === accountStatus.QuickAccepted &&
        !creditOrdered &&
        !!isLegalEntityHasAppropriateTitle &&
        !!isOwnershipPercentageValid &&
        !!hasWholeNumberOwnershipPercents &&
        !!isOwnerSsnValid
      ) {
        setShowBondButton(true);
      }
    } else {
      setShowBondButton(false);
    }
  }, [
    values,
    ownersListData,
    suretyQuote,
    isOwnerSsnValid,
    isOwnershipPercentageValid,
    hasWholeNumberOwnershipPercents
  ]);

  useEffect(() => {
    // needed for licensed states
    fetchSuretyQuoteAgencyData(activeAgencyCode);
  }, [fetchSuretyQuoteAgencyData, activeAgencyCode]);

  useEffect(() => {
    if (parseInt(values.account_number)) {
      fetchOwners(activeAgencyCode, values.account_number, "10");
    }
  }, [values.account_number, activeAgencyCode, fetchOwners]);

  useEffect(() => {
    fetchAgencyAccounts(activeAgencyCode);
  }, [fetchAgencyAccounts, activeAgencyCode]);

  useEffect(() => {
    if (parseInt(values.account_number) && ownersListData.data) {
      ownersListData.data.find(owner => owner.order_credit);
    }
  }, [values.account_number, ownersListData]);

  useEffect(() => {
    setReadyToContinue(
      Object.keys(errors).length === 0 && invalidFields.length === 0
    );
  }, [values, errors, invalidFields]);

  const handleOnFeinValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const regex = /^(?!0+$)\d{9}$/g;
    const allZerosNotAllowed = regex.test(value);
    if (!!value && !allZerosNotAllowed) {
      fieldErrors.push("fein is incomplete");
    }
    return fieldErrors;
  };

  useEffect(() => {
    if (!formInitialized && !!suretyQuote) {
      let opts = [];

      // existing account
      if (
        !!suretyQuote.account_number &&
        parseInt(suretyQuote.account_number) // skip if parsed as 0
      ) {
        opts = [
          {
            value: suretyQuote.account_number,
            label: `${suretyQuote.account_name} (${suretyQuote.city}, ${suretyQuote.state})`,
            account_number: suretyQuote.account_number,
            account_name: suretyQuote.account_name
          }
        ];

        setDisableFields(true);

        // new account
      } else if (!!suretyQuote.account_name) {
        opts = [
          {
            value: suretyQuote.account_number,
            label: `Add Customer: ${suretyQuote.account_name}`,
            account_number: suretyQuote.account_number,
            account_name: suretyQuote.account_name,
            custom: true
          }
        ];
      }

      // update the options for the business name
      setBusinessNameOptions(opts);
    }

    if (
      !initialAgenciesMapped &&
      !!agencyAccountsListData.data &&
      !!agencyAccountsListData.data.length &&
      !suretyQuote
    ) {
      const accountsList = agencyAccountsListData.data.map(a => {
        return {
          value: a.account_number || a.account_name,
          label: `${a.account_name} (${toTitleCase(a.city)}, ${a.state})`,
          account_number: a.account_number,
          account_name: a.account_name
        };
      });

      setBusinessNameOptions(accountsList);
      setInitialAgenciesMapped(true);
    }
    // set the form to be initialized so that individual field changes update the form
    setFormInitialized(true);

    // we only need this to run once to get things going
  }, [
    suretyQuote,
    formInitialized,
    agencyAccountsListData,
    initialAgenciesMapped
  ]);

  const processNewQuoteData = data => {
    let ownerData;
    let isAccountUpdated = false;
    //do we have data saved for the selected account number?
    if (
      suretyQuote?.ownerData &&
      data.account_number === suretyQuote.account_number &&
      data.account_name === suretyQuote.account_name
    ) {
      ownerData = suretyQuote.ownerData;
    } else {
      ownerData = ownersListData.data || [];
    }

    const selectedAccount =
      businessNameOptions.length > 1
        ? businessNameOptions.filter(
            acc => acc.account_number === data.account_number
          )
        : businessNameOptions[0];

    if (
      data.fein !== selectedAccount.fein ||
      data.construction_specialty_dd !==
        selectedAccount.construction_specialty ||
      data.construction_other !== selectedAccount.construction_other
    ) {
      isAccountUpdated = true;
    }

    const quoteData = {
      ...data,
      isAccountUpdated,
      newCustomer: !parseInt(data.account_number),
      ownerData: ownerData.map(o =>
        o.hasOwnProperty("delete") ? o : { ...o, delete: false }
      )
    };

    return quoteData;
  };

  const handleConstructionSpecialtyChange = useCallback(
    ({ event, field, value }) => {
      const classCode = value ? value.substring(0, 9) : "";
      const classDescription =
        SuretyConstructionSpecialty.find(
          o =>
            o.value === value &&
            o.value !== ConstructionSpecialtyTypeOtherNotListed
        )?.label || "";
      updateForm({
        values: {
          construction_specialty: classCode,
          construction_other: classDescription
        }
      });
      handleOnChange({ event, field, value });
    },
    [updateForm, handleOnChange]
  );

  const isConstructionSpecialtyOtherVisible =
    values.construction_specialty === ConstructionSpecialtyTypeOtherNotListed;

  const [constructionSpecialty, setConstructionSpecialty] = useState(undefined);
  const [constructionOther, setConstructionOther] = useState("");

  useEffect(() => {
    const specialty =
      accountSearch.getConstructionSpecialty(
        values?.construction_specialty,
        values?.construction_other
      )?.value || "";
    const other = accountSearch.getConstructionOther(
      specialty,
      values?.construction_other
    );
    setConstructionSpecialty(specialty);
    setConstructionOther(other);
  }, [values, setConstructionSpecialty, setConstructionOther]);

  useEffect(() => {
    const urlAccount = new URLSearchParams(queryParams?.search).get("account");
    const cachedAccount = suretyQuote?.account_number;
    if (
      !agencyAccountsListData?.data?.length ||
      Boolean(urlAccount) === false ||
      (urlAccount === cachedAccount && Boolean(cachedAccount) === true)
    )
      return;
    const account = agencyAccountsListData?.data?.find(
      _ => _.account_number === urlAccount
    );
    if (!account) return;
    history.replace({ search: "" });
    setTimeout(() => {
      handleBusinessNameSearch(urlAccount, { action: "input-change" });
      setTimeout(
        () => handleBusinessNameChange({ value: account?.account_number }),
        150
      );
    }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, agencyAccountsListData, suretyQuote, history]);

  if (!formInitialized || agencyAccountsListData?.isLoading)
    return <LoadingIndicator />;

  return (
    <>
      <ContentHeader>Let&apos;s start with the basics</ContentHeader>
      <div>
        {errorMessageRenewal && (
          <Alert
            id="warning_msg"
            className="qq__alert"
            dismissible
            type={alertType}
          >
            {errorMessageRenewal}
          </Alert>
        )}
      </div>

      <Form className="qq__form__step1 qq__form" context={form}>
        <Panel
          rounded
          bgcolor="grey"
          title="Enter business information"
          titlebar
        >
          <FormGroup>
            {!values.account_name ? (
              <Dropdown
                id="account_name"
                name="account_name"
                label="Search Business Name"
                placeholder="Enter business name..."
                onChange={handleBusinessNameChange}
                onBlur={handleOnBlur}
                onValidate={handleOnBusinessValidate}
                onInputChange={handleBusinessNameSearch}
                value={values.account_number}
                options={businessNameOptions}
                required
                isSearchable
                hideIndicatorUntilSearch
                className="grow"
                noOptionsMessage={() => null}
                loadingMessage={() => "Searching for account..."}
                labelElement={
                  <Popover
                    id="businessTooltip"
                    trigger="hover"
                    triggerContent={<Icon icon="fasInfoCircle" />}
                    popoverContent={Translations.Surety.businessTooltip}
                  />
                }
                inputValue={
                  accountNumberInputValue?.length <= AccountNameMaxLength
                    ? accountNumberInputValue
                    : accountNumberInputValue?.substr(0, AccountNameMaxLength)
                }
              />
            ) : (
              <>
                <Input
                  id="account_name"
                  name="account_name"
                  label="Search Business Name"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnBusinessValidate}
                  value={values.account_name}
                  required
                  className="grow"
                  size="auto"
                  maxLength={AccountNameMaxLength}
                  disabled={!getIsNewAccountOrObligee(values.account_number)}
                />
                <Button
                  labelSpace
                  wrapperClassName="oq__account-search__reset"
                  isLink
                  onClick={handleBusinessNameChange}
                >
                  Reset
                </Button>
              </>
            )}
          </FormGroup>
          <FormGroup>
            <TextArea
              id="legal_business_name"
              name="legal_business_name"
              label="Legal Business Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values?.legal_business_name || ""}
              labelElement={
                <Popover
                  id="formattedTooltip"
                  trigger="hover"
                  maxWidth={300}
                  triggerContent={<Icon icon="fasInfoCircle" />}
                  popoverContent={Translations.Surety.formattedTooltip}
                />
              }
              required
              maxLength={135}
              disabled={disableFields}
            />
          </FormGroup>
          <MultiAddress
            disableFields={disableFields}
            setDisableFields={setDisableFields}
            activeAgencyCode={activeAgencyCode}
            stateOptions={licensedStates}
            suretyZip={suretyZip}
            fetchSuretyZip={fetchSuretyZip}
            form={form}
            checkIsNonSuretyState={!suretyWhitelistNysEnabled}
          />
          <FormGroup>
            <Input
              id="fein"
              name="fein"
              label="FEIN Number"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnFeinValidate}
              value={values.fein}
              maxLength={9}
            />
            <Dropdown
              id="business_type"
              name="business_type"
              label="Legal Entity"
              placeholder="Select a legal entity..."
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.business_type}
              options={
                getIsNewAccountOrObligee(values.account_number)
                  ? suretyLegalEntities.filter(g => g.display)
                  : suretyLegalEntities
              }
              isOptionDisabled={option => option?.disabled}
              isClearable
              size="md"
              required
              disabled={disableFields}
            />
            <Dropdown
              id="construction_specialty_dd"
              name="construction_specialty_dd"
              label="Construction Specialty"
              placeholder="Select a construction specialty..."
              onChange={handleConstructionSpecialtyChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              options={SuretyConstructionSpecialty || []}
              value={constructionSpecialty}
              required
              className="grow"
              size="lg"
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="construction_other"
              name="construction_other"
              label="Construction Specialty Description"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={constructionOther}
              size="lg"
              required={isConstructionSpecialtyOtherVisible}
              className={
                isConstructionSpecialtyOtherVisible
                  ? ""
                  : "hide-construction-specialty-extras"
              }
            />
          </FormGroup>
        </Panel>

        <BottomNav leftNav>
          <FlexRow className="qq__customer-info__bottom-row" align="right">
            <FormGroup wrap={false} align="right">
              <Checkbox
                id="legal_name_verified"
                name="legal_name_verified"
                label="Legal Business Name is formatted correctly as it should appear on the bond"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.legal_name_verified}
                disabled={disableFields}
                noLabel
                required
                className="step1CheckBox"
                requiredError="Required"
              />
            </FormGroup>

            {!showBondButton && (
              <FormGroup align="right">
                <Button
                  variant="primary"
                  onClick={ownerContinue}
                  disabled={!readyToContinue}
                >
                  Continue to Owner Information
                </Button>
              </FormGroup>
            )}

            {showBondButton && (
              <FormGroup align="right">
                <div className="align-right">
                  <Button isLink onClick={ownerContinue}>
                    Review Owner Information
                  </Button>

                  <Button
                    variant="primary"
                    onClick={bondContinue}
                    disabled={!readyToContinue}
                  >
                    Go to Bond Information Page
                  </Button>
                </div>
              </FormGroup>
            )}
          </FlexRow>
        </BottomNav>
      </Form>
      {showVerifyModal && (
        <Modal
          show
          altCloseMethod={false}
          title="Verify Addresses"
          className="qq__verify-address__modal"
          size="lg"
          closeIcon={false}
          body={
            <VerifyAddress
              updateAddressFormFields={updateAddressFormFields}
              addressToVerify={addressToVerify}
              setAddressToVerify={setAddressToVerify}
              handleContinue={verifiedAddressContinue}
              setShowVerifyModal={setShowVerifyModal}
              suretyQuote={suretyQuote}
              updateSuretyQuoteData={updateSuretyQuoteData}
              cacheId={cacheId}
            />
          }
        />
      )}
    </>
  );
};
export default CustomerInfo;

CustomerInfo.propTypes = {
  activeAgencyCode: PropTypes.string.isRequired,
  history: PropTypes.any,
  agencyAccountsListData: PropTypes.any,
  fetchAgencyAccounts: PropTypes.func,
  ownersListData: PropTypes.object.isRequired,
  fetchOwners: PropTypes.func.isRequired,
  clearSuretyQuote: PropTypes.func.isRequired,
  updateSuretyQuoteData: PropTypes.func.isRequired,
  suretyQuote: PropTypes.object,
  cacheId: PropTypes.string.isRequired,
  fetchSuretyQuoteAgencyData: PropTypes.func.isRequired,
  licensedStates: PropTypes.array,
  suretyZip: PropTypes.object,
  fetchSuretyZip: PropTypes.func.isRequired,
  isOwnershipPercentageValid: PropTypes.bool,
  hasWholeNumberOwnershipPercents: PropTypes.bool,
  isOwnerSsnValid: PropTypes.bool,
  clearOwners: PropTypes.func.isRequired
};
