import qs from "query-string";
import API from "../../api";
import { DateFormat } from "../../components/Factory";

export const postExperienceDetail = (data, agency_code) => {
  const queryString = {
    requestor_name: data.name,
    email: data.emailTo,
    document_format: data.reportFormatTypes,
    end_date: DateFormat(data.endDate, "YYYY-MM-DD"),
    begin_date: DateFormat(data.beginDate, "YYYY-MM-DD"),
    order: Number(data.sortByList)
  };
  return API.agent()
    .post(`/reports/agencies/${agency_code}/experience-details`, queryString)
    .then(response => response.data);
};

export const getServiceCenterActivityDates = agency_code => {
  return API.agent().get(
    `/reports/agencies/${agency_code}/summaries/service-center-activity/list`
  );
};

export const getServiceCenterActivityReport = (agencyCode, date) => {
  const queryString = qs.stringify({
    date
  });
  return API.agent().get(
    `/reports/agencies/${agencyCode}/summaries/service-center-activity?${queryString}`,
    { responseType: "blob" }
  );
};
