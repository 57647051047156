export const FETCH_EMAIL_LIST_STARTED = "FETCH_EMAIL_LIST_STARTED";
export const FETCH_EMAIL_LIST_SUCCEEDED = "FETCH_EMAIL_LIST_SUCCEEDED";
export const FETCH_EMAIL_LIST_FAILED = "FETCH_EMAIL_LIST_FAILED";

export const FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_STARTED =
  "FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_STARTED";
export const FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_SUCCEEDED =
  "FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_SUCCEEDED";
export const FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_FAILED =
  "FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_FAILED";

export const FETCH_WEEKLY_PAYMENT_REPORT_LIST_STARTED =
  "FETCH_WEEKLY_PAYMENT_REPORT_LIST_STARTED";
export const FETCH_WEEKLY_PAYMENT_REPORT_LIST_SUCCEEDED =
  "FETCH_WEEKLY_PAYMENT_REPORT_LIST_SUCCEEDED";
export const FETCH_WEEKLY_PAYMENT_REPORT_LIST_FAILED =
  "FETCH_WEEKLY_PAYMENT_REPORT_LIST_FAILED";

//underwriting reports
//app quote status

export const FETCH_APP_QUOTE_STATUS_LIST_STARTED =
  "FETCH_APP_QUOTE_STATUS_LIST_STARTED";
export const FETCH_APP_QUOTE_STATUS_LIST_SUCCEEDED =
  "FETCH_APP_QUOTE_STATUS_LIST_SUCCEEDED";
export const FETCH_APP_QUOTE_STATUS_LIST_FAILED =
  "FETCH_APP_QUOTE_STATUS_LIST_FAILED";

//email Api

export const FETCH_UNDERWRITING_EMAIL_LIST_STARTED =
  "FETCH_UNDERWRITING_EMAIL_LIST_STARTED";
export const FETCH_UNDERWRITING_EMAIL_LIST_SUCCEEDED =
  "FETCH_UNDERWRITING_EMAIL_LIST_SUCCEEDED";
export const FETCH_UNDERWRITING_EMAIL_LIST_FAILED =
  "FETCH_UNDERWRITING_EMAIL_LIST_FAILED";

//Service Center Summary

export const FETCH_SERVICE_CENTER_ACTIVITY_DATES_STARTED =
  "FETCH_SERVICE_CENTER_ACTIVITY_DATES_STARTED";
export const FETCH_SERVICE_CENTER_ACTIVITY_DATES_SUCCEEDED =
  "FETCH_SERVICE_CENTER_ACTIVITY_DATES_SUCCEEDED";
export const FETCH_SERVICE_CENTER_ACTIVITY_DATES_FAILED =
  "FETCH_SERVICE_CENTER_ACTIVITY_DATES_FAILED";

export const FETCH_ADMINISTRATOR_STARTED = "FETCH_ADMINISTRATOR_STARTED";
export const FETCH_ADMINISTRATOR_SUCCEEDED = "FETCH_ADMINISTRATOR_SUCCEEDED";
export const FETCH_ADMINISTRATOR_FAILED = "FETCH_ADMINISTRATOR_FAILED";

export const FETCH_SERVICE_CENTER_PRODUCER_STARTED =
  "FETCH_SERVICE_CENTER_PRODUCER_STARTED";
export const FETCH_SERVICE_CENTER_PRODUCER_SUCCEEDED =
  "FETCH_SERVICE_CENTER_PRODUCER_SUCCEEDED";
export const FETCH_SERVICE_CENTER_PRODUCER_FAILED =
  "FETCH_SERVICE_CENTER_PRODUCER_FAILED";

//AgencyBillEpay

export const FETCH_AGENCY_BILL_PAY_STARTED = "FETCH_AGENCY_BILL_PAY_STARTED";
export const FETCH_AGENCY_BILL_PAY_SUCCEEDED =
  "FETCH_AGENCY_BILL_PAY_SUCCEEDED";
export const FETCH_AGENCY_BILL_PAY_FAILED = "FETCH_AGENCY_BILL_PAY_FAILED";
export const FETCH_BILL_ENROLLMENT_STARTED = "FETCH_BILL_ENROLLMENT_STARTED";
export const FETCH_BILL_ENROLLMENT_SUCCEEDED =
  "FETCH_BILL_ENROLLMENT_SUCCEEDED";
export const FETCH_BILL_ENROLLMENT_FAILED = "FETCH_BILL_ENROLLMENT_FAILED";
export const FETCH_AGENCY_STATEMENT_LIST_STARTED =
  "FETCH_AGENCY_STATEMENT_LIST_STARTED";
export const FETCH_AGENCY_STATEMENT_LIST_SUCCEEDED =
  "FETCH_AGENCY_STATEMENT_LIST_SUCCEEDED";
export const FETCH_AGENCY_STATEMENT_LIST_FAILED =
  "FETCH_AGENCY_STATEMENT_LIST_FAILED";
export const FETCH_PURCHASED_AGENCY_CODES_STARTED =
  "FETCH_PURCHASED_AGENCY_CODES_STARTED";
export const FETCH_PURCHASED_AGENCY_CODES_SUCCEEDED =
  "FETCH_PURCHASED_AGENCY_CODES_SUCCEEDED";
export const FETCH_PURCHASED_AGENCY_CODES_FAILED =
  "FETCH_PURCHASED_AGENCY_CODES_FAILED";
export const FETCH_SPONSORED_AGENCY_CODES_STARTED =
  "FETCH_SPONSORED_AGENCY_CODES_STARTED";
export const FETCH_SPONSORED_AGENCY_CODES_SUCCEEDED =
  "FETCH_SPONSORED_AGENCY_CODES_SUCCEEDED";
export const FETCH_SPONSORED_AGENCY_CODES_FAILED =
  "FETCH_SPONSORED_AGENCY_CODES_FAILED";
export const GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_STARTED =
  "GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_STARTED";
export const GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_SUCCEEDED =
  "GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_SUCCEEDED";
export const GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_FAILED =
  "GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_FAILED";
