import React, { useContext } from "react";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";
import {
  Button,
  FlexRow,
  LoadingIndicator,
  Panel,
  Alert
} from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import ServiceCenterContactInfo from "./ServiceCenterContactInfo";
import "./ServiceCenterSection.scss";

const ServiceCenterSection = ({
  hasServiceCenterAccess,
  accountContacts,
  showServiceCenterModal,
  setShowServiceCenterModal,
  setBindIssueConfirmationModal
}) => {
  const { quoteData, patchQuotePromise, supportingData, toastErrr } =
    useContext(OnlineQuotingContext);

  const hasServiceCenter = !!quoteData?.baseData?.serviceCenterIndicator_UFG;

  const contactFromSupData =
    supportingData?.ServiceCenterAccountData?.accountContactDTO;
  const contactFromMetadata = accountContacts?.find(x =>
    x?.accountContactRoles?.some(y => y?.subtype === "SvcCenter_UFG")
  );

  const contactInfo = contactFromSupData || contactFromMetadata;

  const unEnrollServiceCenter = () => {
    const payload = {
      sessionUUID: quoteData.sessionUUID,
      quoteId: quoteData.quoteID,
      serviceCenterIndicator: false
    };
    patchQuotePromise({
      newData: payload,
      quoteId: quoteData.quoteID,
      updateMessage: "Updating Service Center Account Enrollment"
    }).catch(({ error }) =>
      toastErrr({
        action: "enrollServiceCenter",
        description: "Unable to enroll service center",
        error,
        payload
      })
    );
  };

  const isBeforeBind = showServiceCenterModal === "beforeBind";
  const mode = isBeforeBind ? "beforeBind" : contactInfo ? "edit" : "add";

  if (!hasServiceCenterAccess) return null;

  return (
    <>
      {showServiceCenterModal && (
        <ServiceCenterContactInfo
          onHide={() => setShowServiceCenterModal(false)}
          setBindIssueConfirmationModal={setBindIssueConfirmationModal}
          show={!!showServiceCenterModal}
          accountContacts={accountContacts}
          mode={mode}
          hasServiceCenter={hasServiceCenter}
        />
      )}
      <div>
        {!accountContacts && <LoadingIndicator type="spinner" />}

        <Panel
          title="Service Center"
          noBorder
          className="oq__service-center__panel"
        >
          {!hasServiceCenter && (
            <Alert
              type="note"
              dismissible={false}
              className="oq__service-center__enroll"
            >
              <span>This account is eligible for Service Center</span>
              <span>
                <Button
                  isLink
                  inline
                  onClick={() => setShowServiceCenterModal(true)}
                >
                  Enroll
                </Button>
              </span>
            </Alert>
          )}
          {hasServiceCenter && contactInfo && (
            <>
              <FlexRow className="oq__service-center__contactDetails">
                <ul>
                  <li>
                    {contactInfo?.firstName} {contactInfo?.lastName}
                  </li>
                  {contactInfo?.emailAddress1 && (
                    <li>{contactInfo?.emailAddress1}</li>
                  )}
                  {contactInfo?.workNumber && (
                    <li>{contactInfo?.workNumber}</li>
                  )}
                </ul>
              </FlexRow>
            </>
          )}

          {accountContacts && hasServiceCenter && (
            <FlexRow className="oq__service-center__edit-contact">
              <Button
                onClick={() => {
                  setShowServiceCenterModal(true);
                }}
                isLink
                inline
              >
                Edit Contact
              </Button>
              <div>
                <Button
                  variant="tertiary"
                  isLink
                  inline
                  onClick={() => unEnrollServiceCenter()}
                >
                  Unenroll
                </Button>
              </div>
            </FlexRow>
          )}
        </Panel>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    agentName: sessionSelector.getFirstAndLastName(state),
    activeAgencyName: sessionSelector.getActiveAgencyName(state)
  };
};

export default connect(mapStateToProps, {})(ServiceCenterSection);
