import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PaymentConfirmation from "./PaymentConfirmation";
import ErrorPaymentDetail from "./ErrorPaymentDetail";
import ErrorDeclineDetail from "./ErrorDeclineDetail";
import { withRouter } from "react-router-dom";
import * as routes from "../../constants/routes";

const PaymentConfirmationRouter = ({
  paymentDetails,
  insuredName,
  paymentDetailsResponseStatusCode,
  match,
  history,
  clearAgencySweepPayment,
  isOasisPaymentConfirmationLoading,
  agencySweepPaymentInfo,
  originalOasisInvoiceDetails,
  paymentReportLoading,
  fetchAgencySweepPaymentReport
}) => {
  const accountNumber = match.params.account_number;

  useEffect(() => {
    if (!paymentDetailsResponseStatusCode) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
      );
    }
    return () => {
      clearAgencySweepPayment();
    };
  }, [
    paymentDetailsResponseStatusCode,
    accountNumber,
    history,
    clearAgencySweepPayment
  ]);

  return (
    <div>
      {paymentDetailsResponseStatusCode >= 500 && <ErrorPaymentDetail />}
      {paymentDetailsResponseStatusCode >= 400 &&
        paymentDetailsResponseStatusCode < 500 && <ErrorDeclineDetail />}
      {paymentDetailsResponseStatusCode >= 200 &&
        paymentDetailsResponseStatusCode < 400 && (
          <PaymentConfirmation
            paymentDetails={paymentDetails}
            insuredName={insuredName}
            paymentDetailsResponseStatusCode={paymentDetailsResponseStatusCode}
            isOasisPaymentConfirmationLoading={
              isOasisPaymentConfirmationLoading
            }
            agencySweepPaymentInfo={agencySweepPaymentInfo}
            originalOasisInvoiceDetails={originalOasisInvoiceDetails}
            paymentReportLoading={paymentReportLoading}
            fetchAgencySweepPaymentReport={fetchAgencySweepPaymentReport}
          />
        )}
    </div>
  );
};

PaymentConfirmationRouter.propTypes = {
  paymentDetails: PropTypes.object,
  insuredName: PropTypes.string.isRequired,
  paymentDetailsResponseStatusCode: PropTypes.number,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  clearAgencySweepPayment: PropTypes.func.isRequired,
  isOasisPaymentConfirmationLoading: PropTypes.bool.isRequired,
  agencySweepPaymentInfo: PropTypes.object,
  originalOasisInvoiceDetails: PropTypes.object,
  paymentReportLoading: PropTypes.bool,
  fetchAgencySweepPaymentReport: PropTypes.func.isRequired
};

export default withRouter(PaymentConfirmationRouter);
