import { connect } from "react-redux";
import * as paymentConfirmationSelector from "./paymentConfirmationSelector";
import PaymentConfirmationRouter from "./PaymentConfirmationRouter";
import * as verifyPaymentSelector from "../verify-payment/verifyPaymentSelector";
import {
  clearAgencySweepPayment,
  fetchAgencySweepPaymentReport
} from "./agencySweepPaymentConfirmationAction";

const mapStateToProps = state => ({
  paymentDetails: state.agencySweepPaymentConfirmation.paymentDetails,
  insuredName: paymentConfirmationSelector.getPaymentRequestInsuredName(state),
  paymentDetailsResponseStatusCode:
    paymentConfirmationSelector.getPaymentDetailsResponseStatusCode(state),
  agencySweepPaymentInfo: verifyPaymentSelector.getPaymentInformation(state),
  paymentReportLoading:
    state.agencySweepPaymentConfirmation.paymentReportLoading,
  isOasisPaymentConfirmationLoading:
    paymentConfirmationSelector.isOasisPaymentConfirmationLoading(state)
});

const mapDispatchToProps = {
  clearAgencySweepPayment,
  fetchAgencySweepPaymentReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfirmationRouter);
