import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Translations } from "../../../components/translations";
import MonthlyExperienceContainer from "../../containers/MonthlyExperienceContainer";
import ServiceCenterSummary from "./service-center-summary/ServiceCenterSummary";
import ExperienceDetailContainer from "../../containers/ExperienceDetailContainer";
import { Tabs, Tab } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import { getActiveTabIndex, onTabClick } from "./../../../constants/routes";
import { getisSpecialtyPortalView } from "../../../portalview/changePortalViewStateSelectors";

const Agency = ({
  hasAgencyProductionReportsAccess,
  match,
  history,
  is109015Agent,
  hasServiceCenterAccess,
  isSpecialtyPortalView
}) => {
  const onAgencyTabClick = tabIndex => {
    onTabClick(tabIndex, match, history);
  };

  return (
    <div className="agency-container">
      <div className="agency-service-center">
        <Tabs activeTab={getActiveTabIndex(match)}>
          <Tab
            title={Translations.reports.tabAgency.experienceDetail}
            onClick={onAgencyTabClick}
          >
            <ExperienceDetailContainer
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
            />
          </Tab>
          <Tab
            title={Translations.reports.tabAgency.monthlyExperience}
            onClick={onAgencyTabClick}
          >
            <MonthlyExperienceContainer
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
            />
          </Tab>
          {(is109015Agent || hasServiceCenterAccess) && !isSpecialtyPortalView && (
            <Tab title={`Service Center Activity`} onClick={onAgencyTabClick}>
              <ServiceCenterSummary
                hasServiceCenterAccess={hasServiceCenterAccess}
                is109015Agent={is109015Agent}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  );
};

Agency.propTypes = {
  hasAgencyProductionReportsAccess: PropTypes.bool.isRequired,
  is109015Agent: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  hasServiceCenterAccess: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

export default compose(withRouter, connect(mapStateToProps))(Agency);
