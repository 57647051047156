import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FlexRow,
  Panel,
  Tabs,
  Tab,
  useForm,
  formatDate
} from "@ufginsurance/ui-kit";
import { openPopupWindow } from "../../shared/utils";
import { Eportal } from "../../shared/eportal";
import DocumentUploadModals from "../../components/document-upload/DocumentUploadModals";
import "./AccountServices.scss";
import useGetAccountBondList from "../hooks/useGetAccountBondList";
import useGetPendingActivityList from "../hooks/useGetPendingActivityList";
import AccountServiceButton from "./AccountServiceButton";
import AccountOverviewPendingActivityPanel from "./AccountOverviewPendingActivityPanel";
import useGetBillingsByDate from "../hooks/useGetBillingsByDate";
import * as routes from "../../../constants/routes";
import AccountOverviewBondListPanel from "./AccountOverviewBondListPanel";
import AccountOverviewBillingActivityPanel from "./AccountOverviewBillingActivityPanel";
import { billingCopiesSortingOptions } from "../../../constants/dictionary.js";

const AccountServices = ({ account, agencyCode }) => {
  const [showModal, setShowModal] = useState(false);
  const [bondTypeOptions, setBondTypeOptions] = useState();
  const [accountBondList, accountBondListIsLoading, accountBondListError] =
    useGetAccountBondList(agencyCode, account);

  const [
    pendingActivityList,
    pendingActivityListIsLoading,
    pendingActivityListError
  ] = useGetPendingActivityList(agencyCode, account);

  const [
    billingHistoryList,
    billingHistoryListIsLoading,
    billingHistoryListError
  ] = useGetBillingsByDate(
    agencyCode,
    "1900-01-01",
    formatDate(new Date(), "YYYY-MM-DD"),
    billingCopiesSortingOptions.D
  );

  const allowedQuickBusinessTypes = [
    "LIMITED LIAB CORP",
    "CORPORATION",
    "PARTNERSHIP",
    "PROPRIATORSHIP"
  ];
  const bidBondButtonAllowedStatuses = ["A"]; //Accepted
  const contractButtonAllowedStatuses = ["A", "Q"]; //Accepted, Quick Accepted
  const documentUploadButtonAllowedStatuses = [
    "A",
    "D",
    "I",
    "L",
    "N",
    "B",
    "P",
    "S",
    "Q",
    "X",
    "W"
  ]; // All but Decl-Canc Agent and Expired
  const issueCommercialBondAllowedStatuses = ["A", "B", "Q"];
  const quickQuoteButtonAllowedStatuses = ["D", "L", "B", "Q", "X", "W"]; //Declined, Lost, Non-Contract, Quick Accepted/Delined/New
  const form = useForm({
    values: { currentSearchNumber: "", currentBondStatusFilter: "ALL" },
    onSubmit: () => {}
  });
  const { handleOnBlur, handleOnValidate, values, handleOnChange } = form;
  const handleRequestBidBond = () => {
    if (!account?.account_number) {
      openPopupWindow(Eportal.bidBondRequestUrl);
    } else {
      openPopupWindow(Eportal.getBidRequestLink(account.account_number));
    }
  };

  const handleIssueCommercialBond = () => {
    openPopupWindow(Eportal.quoteCommercialBondUrl);
  };

  const handleIssueContractBond = () => {
    openPopupWindow(Eportal.contractBondIssueUrl);
  };

  const handleQuickAccountBond = () => {
    window.open(
      `${routes.setStepUrl(1)}?account=${account?.account_number}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (!accountBondList) return;
    const BOND_STATUSES = [
      { value: "ALL", label: "All" },
      { value: "OPEN", label: "Open" },
      { value: "CLOSED", label: "Closed" },
      { value: "CANCELLED", label: "Cancelled" },
      { value: "NEW", label: "New" },
      { value: "PENDED", label: "Pended" },
      { value: "DB LAPSED", label: "DB Lapsed" },
      { value: "LAPSED", label: "Lapsed" }
    ];
    const bondStatuses = BOND_STATUSES.filter(
      item =>
        accountBondList.filter(
          bondItem =>
            bondItem.bond_status === item.value || item.value === "ALL"
        ).length > 0
    ).map(item => ({
      value: item.value,
      label: `${item.label} (${
        accountBondList.filter(
          bondItem =>
            bondItem.bond_status === item.value || item.value === "ALL"
        ).length
      })`
    }));
    setBondTypeOptions(bondStatuses);
  }, [accountBondList]);

  return (
    <>
      <Panel
        className="account-overview__account-services"
        bgcolor="grey"
        noBorder
      >
        <b>Account Services:</b>
        <FlexRow>
          <AccountServiceButton
            buttonDisabled={
              !bidBondButtonAllowedStatuses.includes(
                account.account_status_short
              )
            }
            onClickFunction={handleRequestBidBond}
            buttonText={"Request a Bid Bond"}
            popoverId={"account-service-popover"}
          />
          <AccountServiceButton
            buttonDisabled={
              !contractButtonAllowedStatuses.includes(
                account.account_status_short
              )
            }
            onClickFunction={handleIssueContractBond}
            buttonText={"Issue a Contract Bond"}
            popoverId={"contract-bond-popover"}
          />
          <AccountServiceButton
            buttonDisabled={
              !documentUploadButtonAllowedStatuses.includes(
                account.account_status_short
              )
            }
            onClickFunction={() => setShowModal(true)}
            buttonText={"Document Upload"}
            popoverId={"document-upload-popover"}
          />
          <AccountServiceButton
            buttonDisabled={
              !issueCommercialBondAllowedStatuses.includes(
                account.account_status_short
              )
            }
            onClickFunction={handleIssueCommercialBond}
            buttonText={"Issue/Quote Commercial Bond"}
            popoverId={"issue-commercial-bond-popover"}
          />
          <AccountServiceButton
            buttonDisabled={
              !(
                quickQuoteButtonAllowedStatuses.includes(
                  account.account_status_short
                ) && allowedQuickBusinessTypes.includes(account.business_type)
              )
            }
            onClickFunction={handleQuickAccountBond}
            buttonText={"Submit ufgQuick Account/Bond"}
            popoverId={"submit-ufg-bond-popover"}
            hoverText={
              allowedQuickBusinessTypes.includes(account.business_type)
                ? "To update account status, please contact your underwriter."
                : "The account selected is not eligible for the Quick Program.  Please contact your underwriter or select a different account."
            }
          />
        </FlexRow>

        <DocumentUploadModals
          showModal={showModal}
          setShowModal={setShowModal}
          account={account}
        />
      </Panel>
      <Tabs className="account-overview__lists-bond-billing-pending">
        <Tab title="Bond List" id="bondListTab">
          <AccountOverviewBondListPanel
            formContext={form}
            bondTypeOptions={bondTypeOptions}
            panelValues={values}
            isLoading={accountBondListIsLoading}
            error={accountBondListError}
            list={accountBondList}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
          />
        </Tab>
        <Tab title="Billing Activity" id="billingActivityTab">
          <AccountOverviewBillingActivityPanel
            isLoading={billingHistoryListIsLoading}
            error={billingHistoryListError}
            list={billingHistoryList}
            account={account}
            agencyCode={agencyCode}
          />
        </Tab>
        <Tab
          title={`Pending Activity (${
            pendingActivityList ? pendingActivityList.length : 0
          })`}
          disabled={
            pendingActivityListIsLoading ||
            pendingActivityListError ||
            !pendingActivityList?.length
          }
          id="pendingActivityTab"
        >
          <AccountOverviewPendingActivityPanel
            pendingActivityList={pendingActivityList}
          />
        </Tab>
      </Tabs>
    </>
  );
};

AccountServices.propTypes = {
  account: PropTypes.object,
  agencyCode: PropTypes.string
};

export default AccountServices;
