import React, { useContext, useEffect } from "react";
import * as api from "../../services/onlineQuotingService";
import {
  Button,
  Form,
  useForm,
  FormGroup,
  Input,
  InputEmail,
  FlexRow
} from "@ufginsurance/ui-kit";
import OqModal from "../shared/OqModal";
import OnlineQuotingContext from "../OnlineQuotingContext";

import "./ServiceCenterContactInfo.scss";

const ServiceCenterContactInfo = ({
  onHide,
  show,
  accountContacts,
  mode,
  hasServiceCenter,
  setBindIssueConfirmationModal
}) => {
  const {
    getQuotePart,
    patchQuotePromise,
    quoteData,
    supportingData,
    updateSupportingDataPromise,
    showUpdatingToast,
    closeUpdatingToast,
    quoteIsUpdating,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const strings = {
    beforeBind: {
      title: "Would you like to enroll your account for Service Center?",
      continueButton: "Enroll and Continue",
      cancelButton: "Skip Enrollment and Continue"
    },
    edit: {
      title: "Edit Service Center Contact",
      continueButton: !hasServiceCenter
        ? "Update Contact and Enroll"
        : "Update Contact",
      cancelButton: "Cancel"
    },
    add: {
      title: "Add Service Center Contact and Enroll",
      continueButton: "Add Contact and Enroll",
      cancelButton: "Cancel"
    }
  };

  const accountId = getQuotePart("baseData.accountNumber");

  const contactFromSupData =
    supportingData?.ServiceCenterAccountData?.accountContactDTO;
  const constactFromMetadata = accountContacts.find(x =>
    x.accountContactRoles.some(y => y?.subtype === "SvcCenter_UFG")
  );

  const contactInfo = contactFromSupData || constactFromMetadata;

  const initialValues = {
    firstName: contactInfo?.firstName || "",
    lastName: contactInfo?.lastName || "",
    emailAddress1: contactInfo?.emailAddress1 || "",
    emailAddress2: contactInfo?.emailAddress2 || "",
    workNumber: contactInfo?.workNumber || "",
    homeNumber: contactInfo?.homeNumber || "",
    cellNumber: contactInfo?.cellNumber || "",
    faxNumber: contactInfo?.faxNumber || ""
  };

  const onSubmit = () => {
    const payload = {
      accountNumber: accountId,
      accountContactDTO: {
        subtype: "Person",
        primaryPhoneType: "work",
        firstName: values.firstName,
        lastName: values.lastName,
        accountHolder: false,
        additionalAddresses: [],
        emailAddress1: values.emailAddress1 || "",
        emailAddress2: values.emailAddress2 || "",
        workNumber: values.workNumber || "",
        homeNumber: values.homeNumber || "",
        cellNumber: values.cellNumber || "",
        faxNumber: values.faxNumber || "",
        // set the default address to be the mailing address
        primaryAddress:
          supportingData.customerInformation.accountHolder.additionalAddresses.find(
            a => a.addressType === "mailing_ufg"
          )
      },
      inServiceCenter: true
    };

    const message = "Updating Service Center Contact Details";
    showUpdatingToast({ message });
    // we're editing an existing contact
    api
      .updateServiceCenterContact({ accountId, payload })
      .then(response => {
        updateSupportingDataPromise({
          dataToMergeAndSave: {
            ServiceCenterAccountData: response.data
          }
        }).then(() => {
          closeUpdatingToast({ message });
          if (mode === "edit" && hasServiceCenter) {
            // is 'edit' mode, just close the modal
            onHide({ serviceCenterValue: undefined });
          } else {
            patchQuotePromise({
              newData: {
                sessionUUID: quoteData.sessionUUID,
                quoteId: quoteData.quoteID,
                serviceCenterIndicator: true
              },
              quoteId: quoteData.quoteID,
              updateMessage: "Updating Service Center Account Enrollment"
            })
              .then(({ success }) => {
                closeUpdatingToast({ message });
                if (success) {
                  onHide({ serviceCenterValue: true });
                  if (mode === "beforeBind") {
                    setBindIssueConfirmationModal(true);
                  }
                }
              })
              .catch(({ error }) =>
                toastErrr({
                  misc: { accountId },
                  action: "enrollServiceCenter",
                  description: "Unable to enroll service center",
                  error
                })
              );
          }
        });
      })
      .catch(error => {
        closeUpdatingToast({ message });
        toastErrr({
          misc: { accountId },
          action: "updateServiceCenterContact",
          description: "failed to set service center contact",
          error
        });
      });
  };

  // initialize the hook
  const form = useForm({ values: initialValues, onSubmit });

  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    validateForm
  } = form;

  // trigger validation on mount to make button state update
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <OqModal
      size="md"
      title={strings?.[mode]?.title}
      className="oq__modal__service-center-contact"
      body={
        <div>
          <p>
            <b>Service Center Contact Details</b>
          </p>
          <Form context={form}>
            <FormGroup>
              <Input
                id="firstName"
                name="firstName"
                label="First Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.firstName}
                required
                size="50p"
              />
              <Input
                id="lastName"
                name="lastName"
                label="Last Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.lastName}
                required
                size="50p"
              />
            </FormGroup>
            <FormGroup>
              <InputEmail
                id="emailAddress1"
                name="emailAddress1"
                label="Primary Email Address"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.emailAddress1}
                size="50p"
                required
              />
              <Input
                id="workNumber"
                name="workNumber"
                label="Phone Number"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.workNumber}
                required
                mask="phone"
                stripMaskFromValue={false}
                size="50p"
              />
            </FormGroup>
            <FlexRow align="right" className="pad-top">
              <Button
                isLink
                className="oq__service-center__cancel-btn"
                onClick={() => {
                  if (mode === "beforeBind") {
                    setBindIssueConfirmationModal(true);
                  }
                  onHide();
                }}
                disabled={quoteIsUpdating}
              >
                {strings?.[mode]?.cancelButton}
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="oq__service-center__continue-btn"
                disabled={quoteIsUpdating || !!invalidFields.length}
              >
                {strings?.[mode]?.continueButton}
              </Button>
            </FlexRow>
          </Form>
        </div>
      }
      show={show}
      onHide={onHide}
      altCloseMethod={false}
      closeIcon={false}
    />
  );
};

export default ServiceCenterContactInfo;
