import * as types from "./actionTypes";
import * as reportService from "../services/agencyReportService";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";

const fetchServiceCenterActivityDatesStarted = () => ({
  type: types.FETCH_SERVICE_CENTER_ACTIVITY_DATES_STARTED
});

const fetchServiceCenterActivityDatesSucceeded = data => ({
  type: types.FETCH_SERVICE_CENTER_ACTIVITY_DATES_SUCCEEDED,
  payload: data
});

const fetchServiceCenterActivityDatesFailed = error => ({
  type: types.FETCH_SERVICE_CENTER_ACTIVITY_DATES_FAILED,
  payload: error
});

export const fetchServiceCenterActivityDates = agency_code => dispatch => {
  dispatch(fetchServiceCenterActivityDatesStarted());
  return reportService.getServiceCenterActivityDates(agency_code).then(
    res => dispatch(fetchServiceCenterActivityDatesSucceeded(res.data)),
    error => {
      dispatch(fetchServiceCenterActivityDatesFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const fetchServiceCenterActivity = (agencyCode, reportDate) => () => {
  const documentService = new DocumentService();
  documentService.initializeRenderer("/loading-document");
  return reportService
    .getServiceCenterActivityReport(agencyCode, reportDate)
    .then(response => {
      documentService.saveOrOpenDocument(response.data, "service_center.pdf");
      return response;
    })
    .catch(err => {
      documentService.clearRenderer();
      throw err;
    });
};
