import React, { useState, useEffect, useCallback, useContext } from "react";
import * as routes from "../constants/routes";
import * as api from "../services/onlineQuotingService";
import _equal from "lodash/isEqual";
import { LoadingIndicator, FlexRow, ContentHeader } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import {
  massageMetadata,
  transformAnswersToBoolForForm,
  filterOutAutoIfHNO,
  removeDuplicateQuestions
} from "./shared/util";
import { productKeys, quotePath } from "./shared/constants";
import getQuestions from "./step3/getQuestions";
import BottomNavigation from "./shared/BottomNavigation";
import EligibilityCheckbox from "./step3/EligibilityCheckbox";
import FormSetup from "./shared/FormSetup";
import OnlineQuotingContext from "./OnlineQuotingContext";
import "./Step3Eligibility.scss";
import _get from "lodash/get";
import AdvantageModal from "./shared/AdvantageModal";
import { useGetData } from "./shared/useGetData";
const Step3Eligibility = ({ history }) => {
  const {
    quoteData,
    navigateToStep,
    supportingData,
    updateSupportingDataPromise,
    supportingDataIsUpdating,
    step,
    clearLocalQuote,
    toastErrr
  } = useContext(OnlineQuotingContext);

  useEffect(() => {
    if (step !== 3 && !supportingDataIsUpdating)
      updateSupportingDataPromise({
        dataToMergeAndSave: { currentPage: "3" }
      });
  }, [step, updateSupportingDataPromise, supportingDataIsUpdating]);

  //Note current step is 0 indexed...i.e 0,1,-- numberProducts - 1
  // const [metadata, setMetadata] = useState();
  const [panelGroups, setPanelGroups] = useState([]);
  const [continueSpinner, setContinueSpinner] = useState(false);

  const [formIsInvalid, setFormIsInvalid] = useState();
  const [checkboxValidation, setCheckboxValidation] = useState(false);
  // questionValues is the collection of all of the current questions
  // ... and is populated from the FormSetup
  const [questionValues, setQuestionValues] = useState();

  const {
    data: metadata,
    loading,
    error: apiError
  } = useGetData({
    apiCall: () => api.getEligibilityQuestions(quoteData?.quoteID),
    deps: [quoteData?.quoteID]
  });

  if (apiError) {
    toastErrr({
      action: "getEligibilityQuestions",
      description: "unable to get questions",
      error: apiError
    });
  }

  useEffect(() => {
    if (metadata && !panelGroups.length) {
      const panelGroups = [];
      const products = filterOutAutoIfHNO(quoteData, supportingData);

      products.forEach(product => {
        let panels = [];
        //common sections seperated out and should be put at the top of bp7BusinessOnwers
        if (product === "bp7BusinessOwners") {
          const commonPanel = getQuestions(metadata, "common");
          const bp7Panel = getQuestions(metadata, "bp7BusinessOwners");
          panels = removeDuplicateQuestions([...commonPanel, ...bp7Panel]);
        } else {
          panels = metadata ? getQuestions(metadata, product) : [];
        }
        if (panels.length) {
          // insert the panel title to the questions
          panels[0].title = `Questions for ${productKeys[product].label}`;

          panelGroups.push({
            product,
            panels,
            eligibilityChecked:
              !!supportingData[product]?.dateTermsCheckCaptured
          });
        }
      });
      setPanelGroups(panelGroups);
    }
  }, [metadata, panelGroups, quoteData, supportingData]);

  const handleFormSubmit = useCallback(() => {
    if (formIsInvalid) {
      return false;
    }

    toast.dismiss();

    let formValues = {};

    panelGroups.forEach(pg => {
      formValues = { ...formValues, ...questionValues[pg.product] };
    });
    //use null for items not filled in.
    Object.keys(formValues).forEach(key => {
      if (formValues[key] === true) formValues[key] = "true";
      if (formValues[key] === false) formValues[key] = "false";
    });

    const payload = {
      sessionUUID: quoteData.sessionUUID,
      answers: { values: formValues }
    };

    // check if values are already saved in the supporting data, if they are then we don't need to save again
    const anAnsweredQuestionIsDifferent = (Object.keys(formValues) || []).some(
      key =>
        supportingData?.answered_questions?.values?.[key] !== formValues[key]
    );
    if (!anAnsweredQuestionIsDifferent) {
      setContinueSpinner(true);
      history.push(routes.ONLINE_QUOTING_STEP4);
      return;
    }

    setContinueSpinner(true);
    api
      .updateEligibilityQuestions(quoteData.quoteID, payload)
      .then(results => {
        // save checkbox timestamp and the answers in supporting data
        let prods = {};
        panelGroups.forEach(pg => {
          prods = {
            ...prods,
            [pg.product]: {
              ...results.data.supportingData[pg.product],
              dateTermsCheckCaptured: new Date().toLocaleDateString()
            }
          };
        });

        const dataToMergeAndSave = {
          ...results.data.supportingData,
          ...prods
        };

        // update the checkbox confirmation value
        updateSupportingDataPromise({ dataToMergeAndSave }).then(() =>
          navigateToStep({ step: 4 }, ({ success }) => {
            if (success) {
              setContinueSpinner(false);
              history.push(routes.ONLINE_QUOTING_STEP4);
            }
          })
        );
      })
      .catch(error => {
        setContinueSpinner(false);
        toastErrr({
          action: "updateEligibilityQuestions",
          description: "unable to save questions",
          payload,
          error
        });
      });
  }, [
    formIsInvalid,
    panelGroups,
    quoteData?.sessionUUID,
    quoteData?.quoteID,
    questionValues,
    supportingData?.answered_questions?.values,
    history,
    updateSupportingDataPromise,
    navigateToStep,
    toastErrr
  ]);

  const productsWithQuestions = [
    "bp7BusinessOwners",
    "ca7CommAuto",
    "wcmWorkersComp"
  ];
  const products = filterOutAutoIfHNO(quoteData, supportingData).filter(p =>
    productsWithQuestions.includes(p)
  );

  const bp7Locations = _get(quoteData, quotePath.bp7locations, []);
  const location = bp7Locations.find(l => !!l.isPrimary);
  if (!!location?.advantagePointDistanceToCoastRules) {
    return (
      <AdvantageModal
        Location={location}
        onCancel={() => {
          clearLocalQuote({ goto: routes.COMMERCIAL_LINES });
        }}
        exitQuote
      />
    );
  }
  return (
    <div id="oq-step3" className="oq__step-container">
      <ContentHeader>Ready to check eligibility?</ContentHeader>
      {loading ? (
        <LoadingIndicator
          className="oq__eligibility-questions-loading"
          message="Loading Eligibity Questions"
        />
      ) : panelGroups.length ? (
        panelGroups.map(pg => (
          <FormSetup
            key={productKeys[pg.product].label}
            sectionHeader=""
            panels={massageMetadata(
              pg.panels,
              transformAnswersToBoolForForm(
                supportingData?.answered_questions?.values
              )
            )}
            setFormIsInvalid={setFormIsInvalid}
            onValuesUpdate={({ values }) => {
              const _newvalues = { ...questionValues, [pg.product]: values };
              // only update the state if the new values are different... prevent infinite loop
              if (!_equal(questionValues, _newvalues))
                setQuestionValues(_newvalues);
            }}
            hideSubmitBtn
          />
        ))
      ) : (
        <div>
          <FlexRow>
            <div role="alert" className="alert  alert-danger">
              No Questions found
            </div>
          </FlexRow>
        </div>
      )}
      <BottomNavigation
        left={{
          text: "Back",
          hidden: true,
          onClick: () => {}
        }}
        middle={{
          jsx: (
            <EligibilityCheckbox
              checkboxIsChecked={products.every(
                p => !!supportingData?.[p]?.dateTermsCheckCaptured
              )}
              setCheckboxValidation={setCheckboxValidation}
              products={products}
            />
          )
        }}
        right={{
          text: "Continue to Confirm Coverages",
          onClick: () => handleFormSubmit(true),
          spinner: continueSpinner,
          disabled:
            continueSpinner ||
            formIsInvalid ||
            !checkboxValidation ||
            !questionValues
        }}
      />
    </div>
  );
};

export default Step3Eligibility;
