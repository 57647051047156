import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import {
  Alert,
  Table,
  formatDate,
  Button,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../../../help-desk/helpDeskMessage";
import { getSelectedAgencyCode } from "../../../../selectors/agencySelectors";
import {
  fetchServiceCenterActivityDates,
  fetchServiceCenterActivity
} from "../../../actions/serviceCenterSummaryAction";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const ServiceCenterSummary = ({
  hasServiceCenterAccess,
  selectedAgencyCode,
  serviceCenterActivity,
  fetchServiceCenterActivityDates,
  fetchServiceCenterActivity,
  is109015Agent,
  producerHasServiceCenterAccess
}) => {
  const [viewDocumentError, setViewDocumentError] = useState(null);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  useEffect(() => {
    if (
      fetchServiceCenterActivityDates &&
      selectedAgencyCode &&
      producerHasServiceCenterAccess
    ) {
      fetchServiceCenterActivityDates(selectedAgencyCode);
    }
  }, [
    fetchServiceCenterActivityDates,
    selectedAgencyCode,
    producerHasServiceCenterAccess
  ]);

  //90 days prior
  const date90DaysPast = new Date(
    new Date().getTime() - 90 * 24 * 60 * 60 * 1000
  )
    .toISOString()
    .split("T")[0];
  const last90Days = (serviceCenterActivity.activityDates || [])
    .filter(_ => _.date >= date90DaysPast)
    .sort();

  const viewReport = date => {
    setViewDocumentError(null);
    setIsLoadingReport(true);
    fetchServiceCenterActivity(selectedAgencyCode, date)
      .then(_ => console.log(_))
      .catch(() =>
        setViewDocumentError(<HelpDeskMessage display="anErrorOccurred" />)
      )
      .finally(() => setIsLoadingReport(false));
  };

  if (
    !!serviceCenterActivity?.error &&
    serviceCenterActivity?.error?.data?.error_code !== 404
  )
    return <HelpDeskMessage display="anErrorOccurred" />;

  if (!!serviceCenterActivity?.isLoading) return <LoadingIndicator />;

  return (
    <>
      <div className="reports-above-form-message">
        <HelpDeskMessage display="questionsOrAssistance" />
      </div>
      <div>
        {selectedAgencyCode && selectedAgencyCode === "109015" ? (
          <div className="agency-error-message">
            {Translations.reports.internal_user}
          </div>
        ) : hasServiceCenterAccess && producerHasServiceCenterAccess ? (
          <div className="service-center-activity">
            {viewDocumentError && (
              <div className="reports-above-form-message">
                <Alert type="error">{viewDocumentError}</Alert>
              </div>
            )}
            <p>
              View your service center totals, new/renewal business, and call
              activity summary.
            </p>
            {!last90Days.length ? (
              <p>
                There are no Service Center Activity reports for your agency in
                the last 90 days.
              </p>
            ) : (
              <>
                <div className="table-title">
                  <span>Service Center Activity</span>
                </div>
                <Table
                  className="noHeaders"
                  rowKey="date"
                  data={last90Days}
                  columns={[
                    {
                      key: "date",
                      label: "",
                      sortable: false,
                      element: row => {
                        return (
                          <>
                            <span className="date">
                              {formatDate(row.date, "MM/DD/YYYY")}
                            </span>
                            <Button
                              onClick={() => viewReport(row.date)}
                              isLink
                              inline
                              spinner={isLoadingReport}
                              disabled={isLoadingReport}
                            >
                              View Report
                            </Button>
                          </>
                        );
                      }
                    }
                  ]}
                  itemsPerPage={15}
                  showPagination
                />
              </>
            )}
          </div>
        ) : (
          <div className="agency-error-message">
            {is109015Agent
              ? "The agency code entered is not affiliated with UFG Service Center. Please ensure the agency code was entered correctly."
              : "You do not have access to the Service Center Activity Report for your agency. In order to gain access, please contact your agency administrator."}
          </div>
        )}
      </div>
    </>
  );
};

ServiceCenterSummary.propTypes = {
  hasServiceCenterAccess: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  serviceCenterActivity: PropTypes.shape({
    activityDates: PropTypes.array,
    isLoading: PropTypes.bool,
    error: PropTypes.any
  }),
  fetchServiceCenterActivityDates: PropTypes.func.isRequired,
  fetchServiceCenterActivity: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool,
  producerHasServiceCenterAccess: PropTypes.bool
};

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  serviceCenterActivity: state.serviceCenterSummary,
  producerHasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state)
});

const mapDispatchToProps = {
  fetchServiceCenterActivityDates,
  fetchServiceCenterActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCenterSummary);
