import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import QuickQuoteHome from "../../quick-program/quick-quoting/QuickQuoteHome";
import UWReviewContainer from "../../underwriter-review/uwReviewContainer";
import PacketDownloadContainer from "../../quick-program/quick-quoting/packet-dl/PacketDownloadContainer";
import { SuretyContextProvider } from "../surety-context/SuretyContext";
import * as routes from "../../constants/routes";
import ConsentOfSurety from "../consent-of-surety/ConsentOfSurety";
import { getProducer } from "../../selectors/agentSelectors";
import "../styles/surety-home.scss";
import BondOverviewModal from "../bond-overview/BondOverviewModal";

const SuretyRouterFrameless = ({ agency, producer, ssoSession }) => {
  return (
    <SuretyContextProvider
      ssoSession={ssoSession}
      producer={producer}
      agency={agency}
    >
      <Switch>
        <Route
          exact
          path={routes.SURETY_PACKET_DOWNLOAD}
          component={PacketDownloadContainer}
        />
        <Route path={routes.QUICK_QUOTING} component={QuickQuoteHome} />
        <Route path={routes.UW_REVIEW} component={UWReviewContainer} />
        <Route
          path={routes.SURETY_CONSENT_OF_SURETY}
          component={ConsentOfSurety}
        />
        <BondOverviewModal />
        <Route render={() => <Redirect to={routes.HOME} />} />
      </Switch>
    </SuretyContextProvider>
  );
};

SuretyRouterFrameless.propTypes = {
  agency: PropTypes.object.isRequired,
  producer: PropTypes.object.isRequired,
  ssoSession: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    agency: sessionSelector.getActiveAgency(state),
    producer: getProducer(state),
    ssoSession: state?.ssoSession
  };
};

export default connect(mapStateToProps)(withRouter(SuretyRouterFrameless));
