import React, { useState, useMemo } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  FlexRow,
  Button,
  Modal,
  Icon,
  TextArea,
  Form,
  FormGroup,
  useForm
} from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import {
  issuePolicyChangeDriver,
  referToUnderwriter
} from "../../redux/policyChangesService";
import { usePolicyChangesContext } from "../context";
import * as Routes from "../../../constants/routes";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import {
  mapPolicyChangeUrl,
  POLICY_WIZARD_STEPS
} from "../../constants_helpers";

const Step2DriverFooter = ({ history, showStep2Loader }) => {
  const { policyData } = usePolicyChangesContext();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {};

  const form = useForm({ values: { note: "" }, onSubmit });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const {
    isBindBlocked,
    submitButtonText,
    confirmationMessage,
    confirmationTitle,
    confirmText,
    cancelText,
    successTitle,
    successClose,
    successMessage,
    shouldDisableSubmit
  } = useMemo(() => {
    const isBindBlocked = policyData?.policyInfo?.bind_blocked;

    const confirmationMessage = isBindBlocked
      ? ""
      : "Are you sure you want to issue this change request?";
    const confirmationTitle = isBindBlocked
      ? "Send for Underwriting Review"
      : "Issue Confirmation";
    const confirmText = isBindBlocked ? "Send" : "Yes";
    const cancelText = isBindBlocked ? "Cancel" : "No";
    const submitButtonText = isBindBlocked
      ? "Send for Underwriter Review"
      : "Issue Change";

    const successTitle = isBindBlocked
      ? "Confirmation"
      : "Policy Change Issued";
    const successClose = isBindBlocked ? "Exit" : "Return to UFG Agent";
    const successMessage = isBindBlocked
      ? "Thank you for submitting your change request for underwriter review.  Your underwriter will be in contact with any questions before issuing your change."
      : "A confirmation of your policy change has been emailed to you for your records. View a record of endorsements under Policy Activity.";

    const shouldDisableSubmit = !policyData?.policyInfo?.policy_diff?.find(
      change => change.change_label === "Drivers"
    );
    return {
      isBindBlocked,
      submitButtonText,
      confirmationMessage,
      confirmationTitle,
      confirmText,
      cancelText,
      successTitle,
      successClose,
      successMessage,
      shouldDisableSubmit
    };
  }, [
    policyData?.policyInfo?.bind_blocked,
    policyData?.policyInfo?.policy_diff
  ]);

  const sendToUnderwriterReview = () => {
    setIsLoading(true);
    referToUnderwriter(
      policyData?.policyInfo?.policy_number,
      policyData?.policyInfo?.job_id,
      values.note.trim() ? values.note : null
    )
      .then(() => {
        setShowConfirmationModal(false);
        setShowSuccessModal(true);
      })
      .catch(() => {
        const options = {
          position: "top-center",
          className: "error",
          autoClose: false
        };
        toast(<HelpDeskMessage display="anErrorOccurred" />, options);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmIssue = () => {
    setIsLoading(true);
    issuePolicyChangeDriver(
      policyData?.policyInfo?.policy_number,
      policyData?.policyInfo?.job_id
    )
      .then(() => {
        setShowConfirmationModal(false);
        setShowSuccessModal(true);
      })
      .catch(() => {
        const options = {
          position: "top-center",
          className: "error",
          autoClose: false
        };
        toast(<HelpDeskMessage display="anErrorOccurred" />, options);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const navigateToStepOne = () => {
    const policyChangeUrl = mapPolicyChangeUrl(
      policyData?.policyInfo?.policy_number,
      policyData?.policyInfo?.job_id,
      POLICY_WIZARD_STEPS.ONE
    );
    history.push(policyChangeUrl);
  };

  return (
    <>
      {!showStep2Loader && policyData?.policyInfo?.policy_diff && (
        <FlexRow align="justify" className="step-two-driver-footer">
          <Button
            disabled={!policyData?.policyInfo?.job_id || isBindBlocked}
            onClick={navigateToStepOne}
            variant="tertiary"
            isLink
            inline
          >
            Back to Edit Driver List
          </Button>

          <Button
            disabled={shouldDisableSubmit}
            onClick={openConfirmationModal}
            variant="primary"
          >
            {submitButtonText}
          </Button>
        </FlexRow>
      )}
      <ConfirmationModal
        id="driver-change__confirmation-modal"
        show={showConfirmationModal}
        title={confirmationTitle}
        message={
          <div>
            {isBindBlocked && (
              <Form context={form}>
                <FormGroup>
                  <TextArea
                    id="note"
                    name="note"
                    label="Comments"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.note}
                    maxLength={234}
                  />
                </FormGroup>
              </Form>
            )}

            {!isBindBlocked && (
              <>
                <p>{confirmationMessage}</p>
              </>
            )}
          </div>
        }
        confirmDisabled={isLoading}
        cancelDisabled={isLoading}
        confirmSpinner={isLoading}
        confirmText={confirmText}
        cancelText={cancelText}
        altCloseMethod
        closeIcon
        handleClose={() => {
          setShowConfirmationModal(false);
          updateForm({
            values: {
              note: ""
            }
          });
        }}
        handleConfirm={isBindBlocked ? sendToUnderwriterReview : confirmIssue}
      />

      <Modal
        id="driver-change__issued-modal"
        show={showSuccessModal}
        title={successTitle}
        body={
          <FlexRow>
            {!isBindBlocked && (
              <div>
                <Icon size="2x" icon="fasCheckCircle" />
              </div>
            )}
            <div>
              <p>{successMessage}</p>
            </div>
          </FlexRow>
        }
        footer={
          <FlexRow>
            <Button
              onClick={() => {
                history.push(
                  `${Routes.ACCOUNT_HOME}/${policyData?.policyInfo?.policy_number}/change-requests`
                );
              }}
              variant="primary"
            >
              {successClose}
            </Button>
          </FlexRow>
        }
        altCloseMethod={false}
        closeIcon={false}
        onHide={() => {
          setShowSuccessModal(false);
        }}
      />
    </>
  );
};

Step2DriverFooter.propTypes = {
  history: PropTypes.object.isRequired,
  showStep2Loader: PropTypes.bool.isRequired
};

export default withRouter(Step2DriverFooter);
