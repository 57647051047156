import { useRef, useEffect, useState } from "react";

export const useGetData = ({ apiCall, deps = [] }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const refRanOnce = useRef(false);
  const refDeps = useRef(deps);

  useEffect(() => {
    const depsChanged =
      JSON.stringify(refDeps.current) !== JSON.stringify(deps);

    if (!refRanOnce.current || depsChanged) {
      refRanOnce.current = true;
      refDeps.current = deps;

      apiCall()
        .then(results => {
          setData(results.data);
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [deps, apiCall]);

  return { data, loading, error };
};
