const initialState = {
  portalViewState: {
    isSpecialtyPortalView: false,
    isUfgSpecialtyBrokerPortalView: false
  },
  paymentDetails: {
    payment: {
      return_code: null,
      eligibility: false
    },
    isLoading: false,
    isActivated: false,
    error: undefined
  },
  policyChangeRequest: {
    driverLicenseValidationList: {
      data: null,
      isLoading: false,
      error: undefined
    },
    policyChangeInformation: {
      data: null,
      isLoading: false,
      error: undefined
    },
    policyDraft: {
      data: null,
      isLoading: false,
      error: undefined
    },
    isActivated: false
  },
  news: {
    isLoading: false,
    newsCurrent: [],
    newsArchive: [],
    agencyState: "",
    newsCurrentError: undefined,
    newsArchiveError: undefined
  },
  currentPolicyData: {
    currentSummary: [],
    oasisDocumentSummaryCurrent: [],
    oasisDocumentSummaryIsLoadingCurrent: false,
    oasisDocumentSummaryErrorCurrent: undefined,
    oasisDocumentSummaryRenewal: [],
    oasisDocumentSummaryIsLoadingRenewal: false,
    oasisDocumentSummaryErrorRenewal: undefined,
    ufgDocuments: {},
    currentSummaryIsloading: false,
    currentSummaryError: undefined
  },
  activityData: {
    activityList: [],
    isActivityLoading: undefined,
    errorActivityList: undefined,
    cancellationList: [],
    errorCancellationList: undefined,
    isCancellationLoading: false
  },
  appQuoteStatusList: {
    submissions: [],
    isLoading: false,
    error: null
  },
  yearToDateLossListReport: {
    yearToDateLossList: [],
    isLoading: false,
    error: null
  },
  weeklyPaymentListReport: {
    weeklyPaymentList: [],
    isLoading: false,
    error: null
  },
  billingDetails: {
    isPrintPostFailed: false,
    activities: [],
    unapplied_amount: null,
    isLoading: false,
    error: null
  },
  installmentDetails: {
    print_installment_data: {},
    isPrintPostFailed: false,
    installments: [],
    isLoading: false,
    error: null
  },
  policiesData: {
    search_result: [],
    isLoading: false,
    error: null,
    searchKeyword: "",
    show: false,
    search_type: "",
    isReportClaim: false,
    isLoadingPolicyTermsHistory: false,
    policyTermsHistoryError: false,
    hasPolicyTermsHistory: false,
    policyTermsHistory: []
  },
  umailData: {
    isLoading: false,
    isActivated: false,
    error: undefined
  },
  helpDesk: {
    isActivated: false,
    helpDeskDetails: {},
    isLoading: false,
    helpDeskError: undefined
  },
  billingCopiesList: {
    data: null,
    isLoading: false,
    customMsg: "",
    error: undefined
  },
  suretyBondFormList: {
    bondFormData: [],
    isLoading: false,
    error: undefined
  },
  agencyProducersList: {
    producerData: [],
    isLoading: false,
    error: undefined
  },
  agencyAccountsList: {
    data: null,
    isLoading: false,
    error: undefined
  },
  quickAgencyAccountsList: {
    data: null,
    isLoading: false,
    error: undefined
  },
  quickOwnersList: {
    data: null,
    isLoading: false,
    error: undefined
  },
  quickEligibilityList: {
    eligibilityData: [],
    isLoading: false,
    error: undefined
  },
  quickBondObligeeList: {
    data: [],
    isLoading: false,
    error: undefined
  },
  quickSubmission: {
    submissionData: null,
    prevSubmissionData: null,
    isLoading: false,
    isLongSubmission: false,
    error: undefined,
    currentSubmissionStep: null
  },
  quickUnderWriterDecision: {
    data: null,
    isLoading: false,
    error: undefined
  },
  quickUnderWriterReviewBond: {
    uwBondInfoData: [],
    isLoading: false,
    error: undefined
  },
  quickUnderWriterReviewAccountInfo: {
    uwAccountInfoData: [],
    isLoading: false,
    error: undefined
  },
  quickUnderWriterWarningMessages: {
    data: [],
    isLoading: false,
    error: undefined
  },
  quickLicensedStatesList: {
    data: null,
    isLoading: false,
    error: undefined
  },
  quickVerifyAddress: {
    data: null,
    isLoading: false,
    error: undefined
  },
  bidRequestListReducer: { data: null, isLoading: false, error: undefined },
  commercialQuotesList: {
    data: null,
    isLoading: false,
    error: undefined
  },
  consentBondsListReducer: {
    data: null,
    isLoading: false,
    error: undefined
  },
  uploadHistoryListReducer: {
    data: null,
    isLoading: false,
    error: undefined
  },
  executedBondsListReducer: {
    data: null,
    isLoading: false,
    error: undefined
  },
  commercialLines: {
    quoteProposals: {
      currentFilter: 30,
      items: {},
      isLoading: false,
      error: null,
      pastFetchedQuoteProposalsCode: null,
      pastQuoteProposalsFilter: null,
      pastFetchedQuoteProposalsSpecialtyCompanyBranch: null
    },
    submissionList: [],
    messages: {},
    attachmentData: [],
    isLoading: false,
    isMessagesLoading: false,
    isAttachmentsLoading: false,
    isReplyMessageLoading: false,
    isStartConversationLoading: false,
    uploadProgress: {},
    loadingProposalDocumentIds: []
  },
  classAppetiteGuide: {
    stateList: [],
    productList: [],
    classifications: []
  },
  documentLibrary: {},
  commercialValue: {},
  riskControl: {},
  newBusiness: {},
  viewUnderwritingTeam: {},
  directBillPayNowEnrollment: {
    show: false,
    isLoading: false,
    error: null,
    enrollment: {}
  },
  claims: {
    claims: [],
    payments: {},
    claimants: {},
    isLoading: false,
    sort: "desc",
    paymentDetails: null,
    printPaymentDetails: {
      isLoading: false,
      error: false
    }
  },
  claimsInquiry: {
    isActivated: false,
    error: null,
    claimNumber: ""
  },
  producerEmailList: {
    emailList: [],
    isLoading: false,
    error: null
  },
  agencyStatementList: {
    statementList: [],
    isLoading: false,
    error: null,
    isLoadingPurchasedAgencyCodes: false,
    purchasedAgencyCodes: [],
    purchasedAgencyCodesError: false,
    sponsoredAgencyCodes: [],
    isLoadingSponsoredAgencyCodes: false,
    sponsoredAgencyCodesError: false
  },
  administrator: {
    details: {},
    isLoading: false,
    error: null
  },
  underwritingProducerEmailList: {
    emailList: [],
    isLoading: false,
    error: null,
    isServiceCenterAccessLoading: false,
    serviceCenterAccess: {},
    errorServiceCenterAccess: false
  },
  serviceCenterSummary: {
    isLoading: false,
    activityDates: [],
    error: null
  },
  homeCommercial: {
    renewalUpdatesList: [],
    isLoading: false,
    policyDecsList: [],
    clientNoticesList: [],
    policyDecsCommercialDocumentFailed: false,
    pastFetchedRenewalAgencyCode: null
  },
  homeServiceCenter: {
    certificateHistoryList: [],
    isLoading: false,
    error: null
  },
  homePersonal: {
    isLoading: false,
    policyDecsList: [],
    clientNoticesList: []
  },
  homeBilling: {
    billingDueSoonList: [],
    pastDueLoading: false,
    dueSoonLoading: true,
    billingPastDueList: [],
    dueSoonError: null,
    pastDueError: null,
    pastDueFetched: false,
    dueSoonFetched: false,
    pastDueFetchedAgencyCode: null,
    dueSoonFetchedAgencyCode: null
  },
  homeClaims: {
    claimsStatusesList: [],
    claimsStatusesLoading: false,
    claimsStatusesError: null,
    claimsStatusDeleteError: null,
    claimsOpenedList: [],
    claimsOpenedLoading: false,
    claimsOpenedError: null,
    claimOpenedDeleteError: null,
    claimPaymentsList: [],
    claimPaymentsLoading: false,
    claimPaymentsError: null,
    claimPaymentDeleteError: null,
    SavedLossesIsLoading: false,
    SavedLossesList: [],
    SavedLossesError: null,
    SubmittedLossesIsLoading: false,
    SubmittedLossesList: [],
    SubmittedLossesError: null,
    pastFetchClaimsStatusAgencyCode: null,
    pastFetchedSavedLossesAgencyCode: null,
    pastFetchedSubmittedAgencyCode: null,
    pastFetchedClaimsOpenedCode: null,
    pastFectchedClaimsPaymentCode: null
  },
  auditBreakdown: {
    audits: [],
    isLoading: false,
    error: null
  },
  moratorium: {
    listings: {},
    details: [],
    isLoading: true,
    error: null
  },
  reportClaim: {
    startReportAClaim: false,
    policyNumber: null,
    reportInformation: {},
    isLoading: false,
    error: null
  },
  websiteLogoUsage: {
    agencyLinkingLoading: false,
    agencyLinkingError: false,
    agencyLinking: null
  },
  agencyBillEpay: {
    billPayLoading: false,
    billPayDetails: null,
    billPayError: false,
    billEnrolLoading: false,
    billEnrollDetails: null,
    billEnrollError: false
  },
  yourUfgTeam: {
    departmentData: [],
    departmentLoading: false,
    departmentError: null,
    contactDetails: [],
    contactLoading: false,
    contactError: null,
    previousCompanyBranch: null,
    previousAgencyCode: null,
    previousDepartment: null
  },
  suretyQuote: {
    data: null,
    isLoading: true,
    error: null
  },
  suretyQuoteClearData: {
    data: null,
    isLoading: false,
    error: null
  },
  suretyQuoteAgencyData: {
    data: null,
    isLoading: false,
    error: null
  },
  suretyZip: {
    data: null,
    isLoading: false,
    error: null
  },
  paymentConfirmation: {
    data: null,
    isLoading: false,
    error: null
  },

  agencySweepAccountBillingActivity: {
    accountNumber: null,
    accountData: null,
    billingActivity: null
  },
  agencySweepOasisInvoiceDetail: {
    isLoading: false,
    error: null,
    billingInformation: null
  },
  agencySweepPaymentRequestDetails: {
    agencySweepPaymentInfo: null
  },
  agencySweepPaymentConfirmation: {
    paymentDetailsLoading: false,
    paymentDetails: null,
    paymentDetailsError: null,
    responseStatusCode: 0,
    oasisPaymentConfirmationLoading: false,
    paymentReportLoading: false,
    paymentReportSuccess: false,
    paymentReportError: false
  },
  suretyQuickDocumentsPacket: {
    isLoading: false,
    error: null
  },
  agentManuals: {
    selectedState: null,
    selectedProduct: null,
    states: {
      previousCompanyBranch: "invalidCompanyBranch",
      stateListData: [],
      isLoading: false,
      error: null
    },
    products: {
      previousFetchedStateId: null,
      stateProducts: [],
      isLoading: false,
      error: null
    },
    tableOfContents: {
      previousFetchedProductId: null,
      data: [],
      isLoading: false,
      error: null
    },
    content: {
      previousFetchedContentId: null,
      data: null,
      isLoading: false,
      error: null
    }
  },
  otherChangesModalData: {
    isLoading: false,
    isActivated: false,
    error: undefined
  }
};

export default initialState;
