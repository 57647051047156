import React from "react";
import PropTypes from "prop-types";
import FooterContainer from "../containers/FooterContainer";
import HeaderContainer from "../containers/HeaderContainer";

import "../styles/index.scss";
import "@ufginsurance/ui-kit/build/styles/index.css";

const DefaultLayout = ({ children }) => {
  return (
    // Using inline style for now because base css names and baseline are a mess.
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <HeaderContainer />
      <section id="content" className="default-layout">
        <div id="body" className="container">
          {children}
        </div>
      </section>
      <FooterContainer />
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default DefaultLayout;
