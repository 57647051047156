import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect
} from "react";
import {
  useForm,
  FormGroup,
  Input,
  Dropdown,
  Form,
  FlexRow,
  Button,
  Alert,
  InputEmail,
  Popover,
  Icon
} from "@ufginsurance/ui-kit";
import { SuretyTitleOptions, statesList } from "../constants/suretyDictionary";
import MultiAddress from "../shared/MultiAddress/MultiAddress";
import { toTitleCase } from "../../../components/Factory";
import { formatAddress, formatZip, formatSsn } from "./../shared/util";
import VerifyAddress from "../shared/AddressVerification/VerifyAddress";

const EditOwner = ({
  setShowEditModal,
  editRow,
  setRow,
  ownerData,
  setShowAddModal,
  licensedStates,
  suretyZip,
  fetchSuretyZip,
  setUpdateOwnerData,
  updateOwners
}) => {
  const getInitialValues = () => {
    const defaults = {
      title: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      address_line: "",
      street_name: "",
      zip: "",
      city: "",
      state: "",
      ssn: "",
      title_other: "",
      married_domestic: false,
      spouse_name: "",
      spouse_ssn: "",
      spouse_email: ""
    };

    let newValues = { ...defaults };

    const spouseFullName =
      editRow.spouse_first_name + " " + editRow.spouse_last_name;
    if (!!editRow.spouse_name || !!editRow.married_domestic === true) {
      newValues = {
        ...editRow,
        ssn: editRow.ssn,
        first_name: toTitleCase(editRow.first_name),
        last_name: toTitleCase(editRow.last_name),
        middle_name: toTitleCase(editRow.middle_name),
        email: editRow.email,
        street_name: formatAddress(editRow.street_name),
        zip: formatZip(editRow.zip),
        spouse_name: !!editRow.spouse_name
          ? toTitleCase(editRow.spouse_name)
          : spouseFullName,
        spouse_ssn: editRow.spouse_ssn,
        spouse_email: editRow.spouse_email
      };
    } else {
      newValues = {
        ...editRow,
        first_name: toTitleCase(editRow.first_name),
        last_name: toTitleCase(editRow.last_name),
        middle_name: toTitleCase(editRow.middle_name),
        street_name: formatAddress(editRow.street_name),
        zip: formatZip(editRow.zip)
      };
    }

    if (!SuretyTitleOptions.find(title => title.value === newValues.title)) {
      //title is unknown value - default to other, set unknown value as title description
      newValues.title_other = newValues.title;
      newValues.title = "OTHER";
    }
    return newValues;
  };
  const initialValues = getInitialValues();

  // onSubmit event
  const handleFormSubmit = () => {
    if (valuesChanged) {
      setAddressToVerify({
        address_line: values.address_line,
        city: values.city,
        state: values.state,
        zip: values.zip,
        reference_number: values.reference_number,
        ssn: values.ssn,
        title: values.title
      });

      setValuesChanged(false);
      setShowVerifyModal(true);
    } else {
      SetNewValues(values);
    }

    //replace old owner data
    const newOwners = ownerData.map(owner => {
      if (owner.id === values.id) {
        return values;
      }

      return owner;
    });
    setUpdateOwnerData(newOwners);
  };

  const form = useForm({
    values: initialValues,
    handleSubmit: handleFormSubmit
  });

  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm,
    validateForm
  } = form;

  const [addressToVerify, setAddressToVerify] = useState();

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const [valuesChanged, setValuesChanged] = useState(false);

  const SetNewValues = values => {
    const tempValue = editRow;

    tempValue.title = values.title;
    tempValue.ssn = values.ssn;
    tempValue.street_name = values.street_name?.toUpperCase();
    tempValue.zip = values.zip;
    tempValue.city = values.city.toUpperCase();
    tempValue.state = values.state;

    setShowEditModal(false);
  };

  useEffect(() => {
    setValuesChanged(
      !(
        editRow.title.trim() === form.values.title.trim() &&
        editRow.ssn.replace(/-/g, "").trim() ===
          form.values.ssn.replace(/-/g, "").trim() &&
        editRow.street_name?.toUpperCase().trim() ===
          form.values.street_name?.toUpperCase().trim() &&
        editRow.address_line.toUpperCase().trim() ===
          form.values.address_line.toUpperCase().trim() &&
        editRow.zip.replace(/-/g, "").trim() ===
          form.values.zip.replace(/-/g, "").trim() &&
        editRow.city.toUpperCase().trim() ===
          form.values.city.toUpperCase().trim() &&
        editRow.state.trim() === form.values.state.trim()
      )
    );
  }, [form, editRow]);

  // const memoizeUpdateForm = useCallback(updateForm, []);

  const updateAddressFormFields = useCallback(
    ({ address, isCancel }) => {
      updateForm({
        values: {
          street_name: toTitleCase(address.street_name),
          city: toTitleCase(address.city),
          state: address.state,
          zip: address.zip
        }
      });
      if (isCancel) {
        const originalOwnerData = ownerData.map(owner =>
          editRow.id === owner.id ? editRow : owner
        );
        setUpdateOwnerData(originalOwnerData);
      }
    },
    [updateForm, editRow, ownerData, setUpdateOwnerData]
  );

  //Continue button status check
  const [readyToContinue, setReadyToContinue] = useState(true);

  useEffect(() => {
    if (values.ssn.length === 9) {
      const indexSsn = invalidFields.findIndex(a => a.name === "ssn");

      if (indexSsn > -1) {
        invalidFields.splice(indexSsn, 1);
      }
    }

    setReadyToContinue(
      Object.keys(errors).length === 0 && invalidFields.length === 0
    );
  }, [values, errors, invalidFields]);

  const handleEditContinue = selectedOwnerValues => {
    const updatedOwnerRow = Object.assign(values, selectedOwnerValues);
    updateOwners(ownerData, updatedOwnerRow);
  };

  const handleOnSsnValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const ssnRegex = /^(\d)(?!\1+$)\d{8}$/;
    if (value === "123456789" || !ssnRegex.test(value))
      fieldErrors.push(" Invalid Social Security Number");
    return fieldErrors;
  };

  const handleChangeTitle = fieldObj => {
    if (fieldObj.value !== "OTHER" && values.title_other) {
      updateForm({
        values: {
          title_other: ""
        }
      });
    }
    handleOnChange(fieldObj);
  };

  useLayoutEffect(() => {
    validateForm();
  }, [validateForm]);

  const emailLabelElementPopover = id => (
    <Popover
      tooltip
      id={`${id}_labelElementPopover`}
      trigger="hover"
      triggerContent={<Icon icon={"fasInfoCircle"} />}
      popoverContent="Email requested for DocuSign Indemnity Agreement"
    />
  );

  return (
    <>
      <Alert id="warning_msg" className="qq__alert" dismissible type="note">
        To change other Owner Information, please contact your Underwriter.
      </Alert>

      <Form className="qq__form__addeditowner qq__form" context={form}>
        <FormGroup className="modal_fields">
          <Dropdown
            id="title"
            name="title"
            label="Title"
            placeholder=""
            onChange={handleChangeTitle}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.title}
            options={SuretyTitleOptions}
            size="md"
          />
          {values.title === "OTHER" && (
            <Input
              id="title_other"
              name="title_other"
              label="Other Description"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.title_other}
              className="grow"
            />
          )}
          <Input
            id="first_name"
            name="first_name"
            label="First Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.first_name}
            required
            className="firstName"
            disabled={!!editRow?.first_name && !values?.is_new}
            maxLength={20}
          />
          <Input
            id="middle_name"
            name="middle_name"
            label="Middle Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.middle_name}
            className="middleName"
            disabled={!!editRow && !values?.is_new}
            maxLength={15}
          />
          <Input
            id="last_name"
            name="last_name"
            label="Last Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.last_name}
            required
            className="lastName"
            disabled={!!editRow?.last_name && !values?.is_new}
            maxLength={20}
          />
        </FormGroup>
        <FormGroup>
          <MultiAddress
            showAddPoboxButton
            form={form}
            agencyStates={statesList}
            editRow={editRow}
            setRow={setRow}
            showDefaultAddressField
            stateOptions={licensedStates}
            suretyZip={suretyZip}
            fetchSuretyZip={fetchSuretyZip}
          />
        </FormGroup>
        <FormGroup>
          <Input
            id="ssn"
            name="ssn"
            label="Social Security Number"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnSsnValidate}
            value={formatSsn(values.ssn)}
            required
            size="lg"
            mask="ssn"
            stripMaskFromValue
          />
          <InputEmail
            id="email"
            name="email"
            label="Email"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.email || ""}
            size="lg"
            maxLength={54}
            labelElement={emailLabelElementPopover("email")}
          />
        </FormGroup>

        <p className="spouse-or-domestic-p">
          Spouse or domestic partner information listed below. If this
          information needs updated, please contact your underwriter to request
          a change.
        </p>
        {!!editRow.spouse_name || !!editRow.married_domestic === true ? (
          <>
            <FormGroup className="edit-spouse">
              <Input
                id="spouse_name"
                name="spouse_name"
                label="Spouse or Domestic Partner"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.spouse_name}
                className="name"
                size="lg"
                disabled
              />
              <Input
                id="spouse_ssn"
                name="spouse_ssn"
                label="Social Security Number"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.spouse_ssn}
                mask="ssn"
                className="name"
                size="lg"
                disabled
                stripMaskFromValue
              />
              <InputEmail
                id="spouse_email"
                name="spouse_email"
                label="Spouse Email"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.spouse_email || ""}
                className="name"
                size="lg"
                disabled
                labelElement={emailLabelElementPopover("spouse_email")}
              />
            </FormGroup>
          </>
        ) : (
          <p className="no-spouse-informatio">No spouse information on file.</p>
        )}

        {!showVerifyModal && (
          <FlexRow className="qq__bottom-row">
            <FormGroup className="align-right">
              <Button
                variant="plain"
                onClick={() => {
                  setShowEditModal(false);
                  setRow(initialValues);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                onClick={handleFormSubmit}
                disabled={!readyToContinue}
              >
                Continue
              </Button>
            </FormGroup>
          </FlexRow>
        )}
      </Form>
      {showVerifyModal && (
        <VerifyAddress
          updateAddressFormFields={updateAddressFormFields} //Ill get back to this
          addressToVerify={addressToVerify}
          editRow={editRow}
          setAddressToVerify={setAddressToVerify}
          handleContinue={handleEditContinue}
          setShowVerifyModal={setShowVerifyModal}
          setShowAddModal={setShowAddModal}
          setShowEditModal={setShowEditModal}
        />
      )}
    </>
  );
};

export default EditOwner;
